import React from 'react';
import FoxNotFound from "../resources/bf_fox_page_not_found.png"
import ctrans from "../common/trans"
import { css, Header, Div, Button } from "@bdfx/fukit"
import history from "../common/history"
import {eventGA, plausible} from "../common/tracking"
import Wave from './homepage/wave';


class ErrorPage extends React.PureComponent<{}, {}> {

    componentDidMount() {
        setTimeout(() => {
            eventGA("PAGE", "Error page 404", "ERROR_404")
            plausible("404",{ props: { path: document.location.pathname } })
            console.log('Error 404')
        }, 2000)
    }

    render() {
        return <>
            <Style>
                <div>
                    <Div textAlign="right" paddingTop="150px" minHeight={"350px"}>
                        <Header type={"h1"}>{ctrans("page_not_found")}</Header>
                        <img src={FoxNotFound} alt={ctrans("page_not_found")} />
                        <p>
                            {ctrans("page_not_found_info")}
                        </p>
                        <Button color="green" size="tiny" icon="plus-light" secondary={true} onClick={() => history.push('/')}>{ctrans("create_new_audit")}</Button>

                    </Div>
                    <Div clear="both" />
                </div>
            </Style>
            
            <Wave from="white" to="#D32C30" v={1} />
        </>
    }

}
const Style = css("div")({
    position: "relative",
    textAlign: "left",
    "> div": {
      background: "#fff",
      marginTop: "-300px",
      paddingTop: "350px",
      paddingBottom: "120px",
      "> div": {
        maxWidth: "1200px",
        margin: "auto",
        },
    },
    "img": {
        float: "right",
        marginTop: "-50px",
        marginBottom: "-50px",
        marginLeft: "100px",
        position: "relative",
        zIndex: 2,
    },
    "p": {
        textAlign: "right",
        fontSize: "1.5rem",
    }
})

export default ErrorPage